<template>
  <q-form ref="editForm">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-card title="공정사고 기본정보" class="cardClassDetailInfo">
        <template slot="card-button">
          <q-btn-group outline >
            <!-- <c-btn v-if="!disabled" label="삭제" :editable="editable" icon="delete_forever" @btnClicked="remove" /> -->
            <font v-if="(standardInfo.reportTypeCd === 'IRC0000005' || standardInfo.reportTypeCd === 'IRC0000001')" color="#C10015">
              {{$language('※ 종결처리시 상단탭에서 중간, 최종보고를 선택하여 추가를 할 수 있습니다.')}}
            </font>
            <!-- 종결처리 -->
            <c-btn 
              v-show="!disabled" 
              :isSubmit="isComplete"
              :url="completeUrl"
              :param="accidentInfo"
              :mappingType="completeType"
              label="종결처리" 
              icon="check"
              @beforeAction="completeAccident"
              @btnCallback="completeCallback" />
          </q-btn-group>
        </template>
        <template slot="card-detail">
          <div class="col-3">
            <c-label-text title="사고번호" :value="standardInfo.accidentNo"></c-label-text>
          </div>
          <div class="col-3">
            <c-label-text title="사고명" :value="standardInfo.accidentName"></c-label-text>
          </div>
          <div class="col-3">
            <c-label-text title="사고종류" :value="standardInfo.accidentKindName"></c-label-text>
          </div>
          <div class="col-3">
            <c-label-text title="사고등급" :value="standardInfo.accidentGradeName"></c-label-text>
          </div>
          <div class="col-3">
            <c-label-text title="발생일시" :value="standardInfo.occurrenceDate"></c-label-text>
          </div>
          <div class="col-3">
            <c-label-text title="발생부서" :value="standardInfo.occurrenceDeptName"></c-label-text>
          </div>
          <div class="col-3">
            <c-label-text title="발생형태 대분류" :value="standardInfo.occurrenceModeLargeName"></c-label-text>
          </div>
          <div class="col-3">
            <c-label-text title="발생형태 중분류" :value="standardInfo.occurrenceModeSmallName"></c-label-text>
          </div>
        </template>
      </c-card>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-card title="계획 및 담당자의견" class="cardClassDetailForm">
        <template slot="card-button">
          <q-btn-group outline>
              <c-btn
              v-if="!disabled"
              :url="updateUrl"
              :isSubmit="isSave"
              :param="accidentInfo"
              mappingType="PUT"
              label="저장"
              icon="save"
              @beforeAction="saveAccident"
              @btnCallback="saveCallback" 
            />
          </q-btn-group>
        </template>
        <template slot="card-detail">
          <div class="col-xs-9 col-sm-9 col-md-4 col-lg-4 col-xl-4">
            <c-textarea
              :editable="editable"
              :disabled="disabled"
              :rows="5"
              label="단기계획"
              name="shortPlan"
              v-model="accidentInfo.shortPlan">
            </c-textarea>
          </div>
          <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
            <c-textarea
              :editable="editable"
              :disabled="disabled"
              :rows="5"
              label="중장기 계획"
              name="longPlan"
              v-model="accidentInfo.longPlan">
            </c-textarea>
          </div>
          <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
            <c-textarea
              :editable="editable"
              :disabled="disabled"
              :rows="5"
              label="담당자 의견"
              name="managerOpinion"
              v-model="accidentInfo.managerOpinion">
            </c-textarea>
          </div>
        </template>
      </c-card>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-table
        ref="tableImpr"
        title="개선 목록"
        :columns="gridImpr.columns"
        :data="accidentInfo.accidentImproveModelList"
        :gridHeight="gridImpr.height"
        :merge="gridImpr.merge"
        selection="multiple"
        rowKey="causesNo"
        :usePaging="false"
        :isExcelDown="false"
        :filtering="false"
        :columnSetting="false"
        :editable="editable && !disabled"
      >
        <template slot="table-button">
          <q-btn-group outline >
              <c-btn v-if="editable && !disabled" label="추가" :showLoading="false"  icon="add" @btnClicked="add" />
              <c-btn
              v-if="editable && !disabled && accidentInfo.accidentImproveModelList.length > 0"
              :url="updateCauseUrl"
              :isSubmit="isSave2"
              :param="accidentInfo.accidentImproveModelList"
              mappingType="PUT"
              label="저장"
              icon="save"
              @beforeAction="saveAccident2"
              @btnCallback="saveCallback" 
            />
            <c-btn v-if="editable && !disabled && accidentInfo.accidentImproveModelList.length > 0" label="삭제" :showLoading="false" icon="remove" @btnClicked="removeImpr" />
          </q-btn-group>
        </template>
        <template v-slot:customArea="{ props, col }">
          <template v-if="col.name==='customCol'">
            <component
              :is="imprComponent"
              :col="col"
              :props="props"
              :inputEditable="editable&&!disabled"
              :isImmShow="true"
              :requestContentsCols="requestContentsCols"
              tableKey="causesNo"
              ibmTaskTypeCd="ITT0000040"
              ibmTaskUnderTypeCd="ITTU000065"
              @imprChange="imprChange"
            />
          </template>
        </template>
      </c-table>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </q-form>
</template>

<script>

import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'accident-cause-prevention',

  props: {
    param: {
      type: Object,
      default: () => ({
        iimAccidentId: '',
        stepCd: '',
        reportName: '',
        count: 0,
      }),
    },
  },
  data() {
    return {
      editable: true,
      standardInfo: {
        accidentNo: '',
        accidentName: '',
        accidentKindName: '',
        accidentGradeName: '',
        occurrenceDate: '',
        occurrenceDeptName: '',
        occurrenceModeLargeName: '',
        occurrenceModeSmallName: '',
        accidentStatusCd: '',
      },
      accidentInfo: {
        shortPlan: '',
        longPlan: '',
        managerOpinion: '',
        accidentStautsCd: '',

        accidentImproveModelList: [],
      },
      gridImpr: {
        columns: [
          {
            required: true,
            name: 'causeTypeCd',
            field: 'causeTypeCd',
            label: '원인구분',
            align: 'center',
            style: 'width:15%',
            type: 'select',
            comboItems: [
              { code: 'ICDC000001', codeName: '기술적' },
              { code: 'ICDC000002', codeName: '교육적' },
              { code: 'ICDC000003', codeName: '관리적' },
            ],
            sortable: false,
          },
          {
            required: true,
            name: 'accidentCause',
            field: 'accidentCause',
            label: '사고원인',
            align: 'left',
            type: 'text',
            style: 'width:50%',
            sortable: false,
          },
          {
            name: 'impr',
            field: 'impr',
            label: '개선진행',
            align: 'center',
            child: [
              {
                name: 'customCol',
                field: 'customCol',
                label: '개선번호/개선진행상태',
                align: 'center',
                style: 'width:15%',
                type: 'custom',
                sortable: false
              },
              {
                name: 'improvingMeasures',
                field: 'improvingMeasures',
                label: '개선대책',
                align: 'left',
                style: 'width:20%',
                type: 'text',
                sortable: false
              },
            ]
          },
        ],
        
        data: [],
        height: '315px'
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '80%',
        param: {},
        closeCallback: null,
      },
      colorItems: [],
      requestImprRow: null,
      imprData: null,
      completeUrl: 'transactionConfig.sop.iim.accident.process.prevention.update.url',
      updateUrl: 'transactionConfig.sop.iim.accident.process.prevention.update.url',
      updateCauseUrl: 'transactionConfig.sop.iim.accident.process.prevention.update.cause.url',
      completeType: 'PUT',
      deleteUrl: '',
      searchUrl: '',
      isComplete: false,
      isSave: false,
      isSave2: false,
    };
  },
  computed: {
    disabled() {
      return this.param.stepCd === 'ISPC000005'
    },
    // 개선요청 내용 전달
    requestContentsCols() {
      return ['accidentCause'];
    },
    // 개선요청 Component
    imprComponent() {
      let components = () => import(`${'@/pages/common/ibm/tableImpr.vue'}`);
      return components;
    },
  },
  watch: {
    'param.count'() {
      this.getStdInfo();
      this.getDetail();
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.getUrl = selectConfig.sop.iim.accident.process.get.url;
      this.getInfoUrl = selectConfig.sop.iim.accident.process.prevention.get.url;
      this.updateUrl = transactionConfig.sop.iim.accident.process.prevention.update.url
      this.updateCauseUrl = transactionConfig.sop.iim.accident.process.prevention.update.cause.url
      this.deleteUrl = transactionConfig.sop.iim.accident.process.prevention.delete.url
      
      this.getStdInfo();
      this.getDetail();
    },
    getStdInfo() {
      if (this.param.iimAccidentId) {
        this.$http.url = this.$format(this.getUrl, this.param.iimAccidentId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.standardInfo = _result.data;
          this.updateMode = true;
        },);
      }
    },
    getDetail() {
      if (this.param.iimAccidentId) {
        this.$http.url = this.$format(this.getInfoUrl, this.param.iimAccidentId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.accidentInfo = _result.data;
          this.updateMode = true;
        },);
      }
    },
    // 개선창 닫기 후
    imprChange() {
      this.$refs['tableImpr'].$refs['compo-table'].resetVirtualScroll();
    },
    saveAccident() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) { 
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.accidentInfo.regUserId = this.$store.getters.user.userId
              this.accidentInfo.chgUserId = this.$store.getters.user.userId
              
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveAccident2() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) { 
          if (this.$comm.validTable(this.accidentInfo.accidentImproveModelList, this.gridImpr.columns)) {
            window.getApp.$emit('CONFIRM', {
              title: '확인',
              message: '저장하시겠습니까?',
              
              type: 'info', // success / info / warning / error
              // 확인 callback 함수
              confirmCallback: () => {
                this.accidentInfo.regUserId = this.$store.getters.user.userId
                this.accidentInfo.chgUserId = this.$store.getters.user.userId
                this.isSave2 = !this.isSave2;
              },
              // 취소 callback 함수
              cancelCallback: () => {
              },
            });
          }
        }else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
    add() {
      this.accidentInfo.accidentImproveModelList.splice(0, 0, {
        iimAccidentId: this.param.iimAccidentId,
        causesNo: uid(),
        causeTypeCd: null,
        accidentCause: '',
        improvingMeasures: '',
        sopImprovementIds: '', 
        ibmStepNames: '', 
        editFlag: 'C',
      })
    },
    remove() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(transactionConfig.sop.iim.accident.process.delete.url, this.param.iimAccidentId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            
            this.$emit('emitStep', {
              name: 'closePopup',
              param: {},
            })
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    removeImpr() {
      let selectData = this.$refs['tableImpr'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.deleteUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData),
            };
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$refs['tableImpr'].$refs['compo-table'].clearSelection();
              this.getDetail();
            });
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    completeAccident() {
      this.completeUrl = this.updateUrl;
      this.completeType = 'PUT';
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '종결처리 하시겠습니까?',
        
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.accidentInfo.accidentStatusCd = 'ISPC000005'
          this.accidentInfo.regUserId = this.$store.getters.user.userId
          this.accidentInfo.chgUserId = this.$store.getters.user.userId
          
          this.isComplete = !this.isComplete;
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    completeCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      if (this.accidentInfo.accidentStatusCd) {
        this.$emit('emitStep', {
          name: 'currentStep',
          param: this.accidentInfo.accidentStatusCd 
        })
        this.$emit('emitStep', {
          name: 'setReportTab',
          param: { stepCd: this.accidentInfo.accidentStatusCd,
                   iimAccidnetId: this.param.iimAccidentId  },
        })
      }
    },
  }
};
</script>
